<template>
  <div class="acquista-un-buono">
    <b-container v-if="is_authenticated()">
      <validation-observer v-slot="{ invalid }" ref="observer">
        <b-row >
          <b-col cols="12" class="text-center">
            <h2 class="vb-heading mb-4 pb-4" v-if="!completed && !purchasing">
              Acquista un buono
            </h2>
            <div v-else-if="checkout_message">
              <h3 class="my-4 d-inline-block pt-4 pb-2">
                <b-alert show variant="success" v-html="checkout_message" />
              </h3>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="!completed && !purchasing">
          <b-col md="4" offset-md="1">
            <b-row>
              <b-col>
                <div>
                  <p>
                    In questa pagina puoi acquistare un buono da utilizzare sul sito Vacanze by VadoBay.
                  </p>
                  <p>
                    <strong>Come acquistare un buono?</strong>
                  </p>
                  <p>
                    Inserisci il valore del buono e compila il form. Comparirà a quel punto il form per pagare con la tua carta di credito.
                  </p>
                  <div class="acquista-un-buono__cards justify-content-center">
                    <vb-icon :name="'visa'" :size="43" />
                    <vb-icon :name="'mastercard'" :size="43" />
                    <vb-icon :name="'american-express'" :size="43" />
                    <vb-icon :name="'dci'" :size="43" />
                    <vb-icon :name="'discover'" :size="43" />
                  </div>
                </div>
              </b-col>
            </b-row>

            <div>
              <hr class="my-4" />
              <b-row>
                <b-col md="12">
                  <h5 class="vb-heading" v-html="credit_card_amount.name" />
                </b-col>
                <b-col md="12">
                  <!--<p class="text text--sm"><i>Per i decimali usa il punto al posto della virgola. Il valore può contenere solo cifre.</i></p>-->
                  <vb-input
                    v-model="credit_card_amount.value" 
                    :vb_options="{
                      required: true,
                      name:credit_card_amount.name,
                      price: true,
                      value: null,
                      formatter: format_price,
                      placeholder: credit_card_amount.placeholder || 100,
                      rules: credit_card_amount.rules,
                    }"/>
                  <!--<validation-provider
                    :rules="credit_card_amount.rules"
                    :name="credit_card_amount.name"
                    v-slot="{ errors, validate }">
                      <vb-input
                        v-model="credit_card_amount.value"
                        :vb_options="{
                          name: credit_card_amount.name,
                          placeholder: credit_card_amount.placeholder || 'asd',
                          required: true,
                        }"/>
                      <span class="vee--errors">{{ errors[0] }}</span>
                  </validation-provider>-->
                </b-col>
              </b-row>
            </div>
          </b-col>

          <b-col md="5" offset-md="1">
            <div v-if="!completed && !purchasing">
              <h5 class="vb-heading" v-html="'Dati di fatturazione'" />
              <hr />
              <b-row>
                <b-col cols="12" sm="6" v-for="(field,key) in fields" v-bind:key="'input_'+key">
                  <validation-provider
                    :rules="field.rules"
                    :name="field.name"
                    :vid="key"
                    v-slot="{ errors, validate }">
                    <div v-if="field.type == 'input'">
                      <label class="vb-label" v-html="field.name" />
                      <vb-input
                        v-model="fields[key].value"
                        :vb_options="{
                          name: key,
                          placeholder: field.name,
                          required: true,
                        }"/>
                      <span class="vee--errors">{{ errors[0] }}</span>
                    </div>
                  </validation-provider>
                </b-col>
              </b-row>
              <hr />
              <p class="text my-4">
                Il buono ha una durata di 24 mesi eventualmente rinnovabili su tua richiesta.
              </p>
              <validation-provider
                :rules="privacy.rules"
                :name="privacy.name"
                :vid="privacy.name"
                v-slot="{ errors, validate }">            
                <label class="vb-label" v-html="privacy.name" />
                <vb-checkbox
                  v-model="privacy.value"
                  :vb_options="{
                    name: privacy.name,
                    text: '<p>'+privacy.text+'</p>',
                  }"/>
                <span class="vee--errors">{{ errors[0] }}</span>
              </validation-provider>
              <validation-provider
                :rules="gdpr.rules"
                :name="gdpr.name"
                :vid="gdpr.name"
                v-slot="{ errors, validate }">   
                <label class="vb-label" v-html="gdpr.name" />
                <vb-checkbox
                  v-model="gdpr.value"
                  :vb_options="{
                    name: gdpr.name,
                    text: '<p>'+gdpr.text+'</p>',
                  }"/>
                <span class="vee--errors">{{ errors[0] }}</span>
              </validation-provider>
              <stripe :credit_card_amount="number(credit_card_amount.value)" v-show="!invalid" @change="event => handle_stripe_update(event)" />
            </div>
          </b-col>
        </b-row>

        <b-row v-else>
          <b-col cols="12" v-if="purchasing" class="text-center">
            <loader />
          </b-col>
          <b-col cols="12" md="10" offset-md="1">
            <p class="mb-4 pb-4">
              Hai appena acquistato un buono che potrai utilizzare nella pagina di check-out (pagina di pagamento) del sito Vacanze by VadoBay. Ricordati che il sito è accessibile solo partendo dal tuo portale di welfare aziendale.
              <br>
              Ti è stata inviata un'email col promemoria del buono, inclusiva di codice e valore. Restiamo a tua disposizione per qualsiasi chiarimento.
            </p>
          </b-col>
          <b-col cols="12" md="10" offset-md="1" v-if="completed" class="text-center">
            <h5 class="my-4">
              Ecco i dati del buono che hai acquistato
            </h5>
            <div class="my-4">
              <p class="mb-0"><b>Buono:</b> {{ discount_code.discountCode }}</p>
              <p class="mb-0"><b>Valore:</b> {{ discount_code.valueNominal }} €</p>
              <!--<p class="mb-0"><b>Scadenza:</b> {{ format_date_YYYY_MM_DD(new Date(discount_code.tsExpire * 1000), '/') }}</p>-->
            </div>
          </b-col>
        </b-row>
      </validation-observer>
    </b-container>
    <b-container v-else>
      <b-row>
        <b-col md="8" lg="6" offset-lg="1">
          <h2 class="vb-heading mb-4 pb-4">Come acquistare un buono</h2>
          <p>
            1. Effettua l’accesso a questo sito partendo dal tuo portale welfare.<br>
            2. Collegati di nuovo a questa stessa pagina <a href="https://vacanze.vadobay.com/acquista-un-buono">https://vacanze.vadobay.com/acquista-un-buono</a>
          </p>
          <p>
            Potrai acquistare un buono utilizzando la tua carta di credito personale:
          </p>
          <div class="acquista-un-buono__cards justify-content-start">
            <div><!--visa-->
              <svg width="43" height="30" viewBox="0 0 43 30" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-0">
                <path d="M38.6356 30H3.94807C1.76937 30 0 28.2306 0 26.0519V3.94806C0 1.76936 1.76937 0 3.94807 0H38.6488C40.8275 0 42.5968 1.76936 42.5968 3.94806V26.0387C42.5836 28.2306 40.8275 30 38.6356 30Z" fill="#014397"/>
                <path d="M19.4895 19.7008H17.2712L18.6577 11.1841H20.876L19.4895 19.7008Z" fill="white"/>
                <path d="M15.4093 11.1841L13.2966 17.0468L13.0457 15.7792L12.2931 11.9499C12.2931 11.9499 12.2006 11.1841 11.2367 11.1841H7.73761L7.698 11.3293C7.698 11.3293 8.76754 11.5538 10.0219 12.3064L11.9498 19.714H14.2605L17.786 11.1973H15.4093V11.1841Z" fill="white"/>
                <path d="M32.8519 19.7008H34.8854L33.116 11.1841H31.3335C30.5148 11.1841 30.3035 11.8179 30.3035 11.8179L26.9893 19.7008H29.3L29.7621 18.4332H32.5879L32.8519 19.7008ZM30.4092 16.6902L31.5711 13.508L32.2313 16.6902H30.4092Z" fill="white"/>
                <path d="M27.1744 13.2305L27.4913 11.3951C27.4913 11.3951 26.5142 11.0254 25.4975 11.0254C24.4015 11.0254 21.7871 11.5007 21.7871 13.8511C21.7871 16.0562 24.8505 16.0826 24.8505 17.2314C24.8505 18.3934 22.104 18.1821 21.1929 17.4558L20.8628 19.3705C20.8628 19.3705 21.8531 19.8458 23.3716 19.8458C24.8901 19.8458 27.1744 19.0668 27.1744 16.9277C27.1744 14.7094 24.0846 14.5113 24.0846 13.5474C24.0582 12.5835 26.2237 12.7023 27.1744 13.2305Z" fill="white"/>
                <path d="M13.0325 15.7792L12.2799 11.9499C12.2799 11.9499 12.1875 11.1841 11.2235 11.1841H7.72443L7.68481 11.3293C7.68481 11.3293 9.36175 11.6726 10.9727 12.9799C12.5308 14.2211 13.0325 15.7792 13.0325 15.7792Z" fill="#FFAF00"/>
              </svg>
            </div>
            <div><!--mastercard-->
              <svg width="43" height="30" viewBox="0 0 43 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M38.6488 30H3.94807C1.76937 30 0 28.2306 0 26.0519V3.94806C0 1.76936 1.76937 0 3.94807 0H38.6488C40.8275 0 42.5968 1.76936 42.5968 3.94806V26.0387C42.5968 28.2306 40.8275 30 38.6488 30Z" fill="#141413"/>
                <path d="M24.8769 8.56958H17.7202V21.4305H24.8769V8.56958Z" fill="#F16022"/>
                <path d="M18.1691 15.0001C18.1691 12.3856 19.3971 10.0749 21.2985 8.56962C19.9121 7.47367 18.1559 6.82666 16.2413 6.82666C11.7254 6.82666 8.06787 10.4842 8.06787 15.0001C8.06787 19.5159 11.7254 23.1735 16.2413 23.1735C18.1427 23.1735 19.8989 22.5265 21.2985 21.4305C19.3971 19.9253 18.1691 17.6145 18.1691 15.0001Z" fill="#E91D25"/>
                <path d="M34.529 15.0001C34.529 19.5159 30.8714 23.1735 26.3556 23.1735C24.4542 23.1735 22.698 22.5265 21.2983 21.4305C23.1997 19.9385 24.4146 17.6145 24.4146 15.0001C24.4146 12.3856 23.1997 10.0749 21.2983 8.56962C22.6848 7.47367 24.441 6.82666 26.3556 6.82666C30.8714 6.82666 34.529 10.4842 34.529 15.0001Z" fill="#F69E1E"/>
              </svg>
            </div>
            <div><!--american express-->
              <svg width="43" height="30" viewBox="0 0 43 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M38.6488 30H3.94807C1.76937 30 0 28.2306 0 26.0519V3.94806C0 1.76936 1.76937 0 3.94807 0H38.6488C40.8275 0 42.5969 1.76936 42.5969 3.94806V26.0387C42.5969 28.2306 40.8275 30 38.6488 30Z" fill="#016FD0"/>
                <path d="M37.3547 14.5478H40.5501V7.11377H37.1435V8.30215L36.3908 7.11377H33.4199V8.61905L32.7597 7.11377H27.8477C27.61 7.11377 27.3855 7.12698 27.1611 7.16659C26.9762 7.193 26.7913 7.23261 26.6065 7.29863C26.4612 7.33824 26.3292 7.39106 26.1971 7.45708C26.0387 7.52311 25.8935 7.61553 25.7614 7.70796V7.45708V7.12698H9.73148L9.29573 8.42099L8.84678 7.12698H5.11V8.64546L4.43658 7.12698H1.38639L0.0263672 10.3092V13.9007V14.561H2.17865L2.57477 13.4914H3.32742L3.73675 14.561H20.3213V13.4782L21.1531 14.561H25.735V14.3233V14.0196C25.8538 14.0988 25.9727 14.1648 26.1047 14.2177C26.2236 14.2705 26.3556 14.3233 26.4876 14.3629C26.6461 14.4157 26.8045 14.4553 26.9762 14.4817C27.2403 14.5346 27.5044 14.5478 27.7552 14.5478H30.5017L30.8978 13.4782H31.6505L32.0598 14.5478H36.6549V13.4386L37.3547 14.5478ZM42.5704 24.0812V17.1094H42.2007H16.1751L15.5017 18.0601L14.8415 17.1094H7.23587V24.5434H14.8019L15.4621 23.5794L16.1487 24.5434H20.889V22.9456H20.8362C21.4304 22.9456 21.9586 22.84 22.4339 22.6684V24.5566H25.735V23.4738L26.5669 24.5566H40.8935C41.3028 24.5566 41.6857 24.4905 42.0422 24.3585C42.2139 24.2793 42.3987 24.1868 42.5704 24.0812Z" fill="#F7F7F7"/>
                <path d="M40.9068 22.3283H38.3319V23.3318H40.8672C41.9499 23.3318 42.5969 22.6716 42.5969 21.7341C42.5969 20.8362 42.0159 20.3213 41.0388 20.3213H39.8768C39.5863 20.3213 39.3883 20.1364 39.3883 19.8459C39.3883 19.5422 39.5863 19.3441 39.8768 19.3441H42.0555L42.5045 18.3274H39.9164C38.8337 18.3274 38.1867 18.9876 38.1867 19.8855C38.1867 20.8098 38.7941 21.338 39.7448 21.338H40.8936C41.184 21.338 41.3821 21.5228 41.3821 21.8265C41.3953 22.1302 41.184 22.3283 40.9068 22.3283ZM36.14 22.3283H33.5652V23.3318H36.1004C37.1832 23.3318 37.8302 22.6716 37.8302 21.7341C37.8302 20.8362 37.2492 20.3213 36.2721 20.3213H35.1101C34.8196 20.3213 34.6215 20.1364 34.6215 19.8459C34.6215 19.5422 34.8196 19.3441 35.1101 19.3441H37.2888L37.7377 18.3274H35.1497C34.067 18.3274 33.42 18.9876 33.42 19.8855C33.42 20.8098 34.0274 21.338 34.9781 21.338H36.1268C36.4173 21.338 36.6154 21.5228 36.6154 21.8265C36.6286 22.1302 36.4173 22.3283 36.14 22.3283ZM32.8258 19.3309V18.3142H28.8117V23.3318H32.8258V22.3151H30.0133V21.3116H32.7598V20.308H30.0133V19.3309H32.8258ZM26.118 19.3441C26.5538 19.3441 26.7782 19.6214 26.7782 19.9647C26.7782 20.308 26.5538 20.5853 26.118 20.5853H24.7052V19.3441H26.118ZM24.7052 21.5228H25.273L26.765 23.3318H28.2571L26.5934 21.4832C27.4517 21.3248 27.9534 20.7042 27.9534 19.9119C27.9534 18.9744 27.2932 18.3274 26.2237 18.3274H23.5036V23.345H24.7052V21.5228ZM21.6418 20.0043C21.6418 20.3477 21.4305 20.6249 21.008 20.6249H19.5555V19.397H20.9948C21.4173 19.3838 21.6418 19.661 21.6418 20.0043ZM18.3539 18.3142V23.3318H19.5555V21.6813H21.0608C22.1568 21.6813 22.8434 20.9683 22.8434 20.0043C22.8434 19.014 22.1832 18.3274 21.1136 18.3274H18.3539V18.3142ZM16.5846 23.3318H18.0899L16.0828 20.8098L18.0767 18.3274H16.611L15.383 19.9119L14.155 18.3274H12.6497L14.6568 20.8362L12.6629 23.345H14.1286L15.3566 21.7341L16.5846 23.3318ZM12.412 19.3309V18.3142H8.39795V23.3318H12.412V22.3151H9.59953V21.3116H12.346V20.308H9.59953V19.3309H12.412Z" fill="#016FD0"/>
                <path d="M35.6778 10.3357L35.6646 9.92635L35.9155 10.3357L37.8301 13.333H39.2297V8.31543H38.0545V11.2204L38.0677 11.5901L37.8433 11.2204L35.9683 8.31543H34.4894V13.333H35.6646V10.3357H35.6778ZM30.8846 10.1904L31.2544 9.25293L31.6241 10.1904L32.073 11.2996H30.4621L30.8846 10.1904ZM32.8785 13.333H34.2253L32.0862 8.31543H30.5017L28.3494 13.333H29.6302L30.0528 12.2635H32.4427L32.8785 13.333ZM27.6232 13.333H27.6892L28.1778 12.2635H27.8081C26.9894 12.2635 26.5008 11.7485 26.5008 10.8638V10.7978C26.5008 10.0056 26.9366 9.41138 27.7949 9.41138H29.1021V8.32863H27.7024C26.1575 8.32863 25.3125 9.37177 25.3125 10.7978V10.8903C25.3125 12.3691 26.1839 13.333 27.6232 13.333ZM23.345 13.333H24.5466V11.1279V8.31543H23.345V11.0883V13.333ZM20.823 9.34536C21.2588 9.34536 21.4832 9.62265 21.4832 9.96596C21.4832 10.3093 21.2588 10.5866 20.823 10.5866H19.4102V9.34536H20.823ZM19.4102 11.5373H19.978L21.47 13.3462H22.9621L21.2984 11.4976C22.1567 11.3392 22.6584 10.7186 22.6584 9.92635C22.6584 8.98885 21.9982 8.34184 20.9287 8.34184H18.2086V13.3594H19.4102V11.5373ZM17.3239 9.34536V8.32863H13.3098V13.3462H17.3239V12.3163H14.5114V11.326H17.2579V10.3225H14.5114V9.34536H17.3239ZM7.35471 9.91314L7.3283 8.5531L8.71474 13.333H9.7975L11.1839 8.57951L11.1575 9.89994V13.333H12.3195V8.31543H10.1936L9.24291 11.8674L8.27901 8.31543H6.16633V13.333H7.3283V9.91314H7.35471ZM2.56157 10.1904L2.9313 9.25293L3.30101 10.1904L3.74995 11.2996H2.13904L2.56157 10.1904ZM4.54221 13.333H5.90225L3.76315 8.31543H2.17865L0.0263672 13.333H1.30717L1.72972 12.2635H4.11968L4.54221 13.333Z" fill="#016FD0"/>
              </svg>
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <img src="assets/images/alpitour/statics/symbol_w_girl.png" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { number } from 'mathjs'
import { service_handler } from '@/services'
import {custom_event, format_date_YYYY_MM_DD, get_config} from '@utilities'
import Stripe from '@src/components/blocks/sidebar/sidebar-stripe'
import * as Validation from '@resources/validation'
export default {
  name: 'acquista-un-buono',
  components: {
    stripe: Stripe,
  },
  created() {
    this.number = number
    this.format_date_YYYY_MM_DD = format_date_YYYY_MM_DD
  },
  computed: {
    payload() {
      return {
        token: this.$config.token,
        bookingData: {
          live_price: this.credit_card_amount.value,
          payments: {
            /*
            fake: {
              amount: 0
            },*/
            cc: {
              amount: this.credit_card_amount.value,
            }
          }
        },
        guestFirstName: this.fields.first_name.value,
        guestLastName: this.fields.last_name.value,
        guestEmail: this.fields.email.value,
        guestEmailConf: this.fields.email_conf.value,
        guestPhone: this.fields.phone.value,
        guestFiscalCode: this.fields.fiscal_code.value,

        guestAddress: (this.fields.address)? this.fields.address.value : '',
        guestCity: (this.fields.city)? this.fields.city.value : '',
        guestPostalCode: (this.fields.postal_code)? this.fields.postal_code.value : '',
        guestProvince: (this.fields.province)? this.fields.province.value : '',

        guestPrivacy: this.privacy.value,
      }
    }
  },
  data() {
    return {
      credit_card_amount: {
        name: "Valore del buono",
        rules: {
          required: true,
          price: true,
          min_value: get_config('guiSettings.dcb.min', 0.00),
          max_value: get_config('guiSettings.dcb.max', 9999.00),
        },
        type: 'input',
        value: null,
        placeholder: '€'
      },
      fields: {
        first_name: Validation.first_name,
        last_name: Validation.last_name,
        fiscal_code: Validation.fiscal_code,
        phone: Validation.phone,
        address: Validation.address,
        city: Validation.city,
        province: Validation.province,
        postal_code: Validation.postal_code,
        email: Validation.email,
        email_conf: Validation.email_conf,
      },
      privacy: Validation.privacy,
      gdpr: Validation.gdpr,
      stripe_completed: false,
      purchasing: false,
      completed: false,
      checkout_message: '',
      discount_code: {},
    }
  },
  props: {
  },
  mounted() {
  },
  methods: {
    handle_stripe_update(event) {
      if (event.name == 'stripe-init') {
        this.payload.bookingData.payments.cc = {
          amount: this.credit_card_amount.value,
        }
        this.stripe_completed = false
      }
      if (event.name == 'stripe-change') {
        // no callback provided
      }
      if (event.name == 'stripe-confirm') {
        if (event.payload.error) {
          this.stripe_completed = false
          window.eventBus.$emit('got_modal_notification',{
            msg: event.payload.message,
            status: event.payload.status,
            callback: function() {}
          })
          return
        }
        this.payload.bookingData.payments.cc.paymentIntentId = event.payload.paymentIntentId
        this.payload.bookingData.payments.cc.nameSurname = event.payload.name
        this.stripe_completed = true
        this.handle_discount_code_purchase()
      }
    },
    handle_discount_code_purchase() {
      if (this.purchasing) return
      this.purchasing = true
      service_handler('dcBuy', this.payload)
        .then(
          (success) => {
            this.purchasing = false
            this.completed = true
            this.checkout_message = success.data.msg
            this.discount_code = success.data.discountCode
            window.scrollTo(0,0)

            custom_event('dcb_buy', '', success.data.discountCode.valueNominal)
          },
          (error) => {
            this.log('[dcBuy] - error')
            this.log(error.response)
            this.checkout_message = 'C\'è stato un problema'
            this.purchasing = false
            custom_event('dcb_buy_error', '', this.credit_card_amount.value)
          }
        )

    }
  }
}
</script>

<style lang="scss">
  .acquista-un-buono {
    padding: $grid-gutter-width*2 0 $grid-gutter-width 0;
    &__cards {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      //justify-content: center;
      margin: 2rem 0 1rem 0;
      svg {
        margin: 0 0.5rem;
        max-width: 40px;
        //filter: grayscale(100%);
      }
    }
  }

</style>